import React from "react"
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Link } from 'gatsby';
import "react-tabs/style/react-tabs.css";

class AboutPage extends React.Component {

    state = {
        tab: '#installaion',
        tabIndex: 0
    }

    tabs = React.createRef();

    componentDidMount() {

        const hashes = ['#service-installation', '#service-maintenance', '#service-repair', '#service-webshop'];
        const hash = this.props.location.hash;
        if (hash) {
            const index = hashes.indexOf(hash);
            const tabIndex = index === -1 ? 0 : index;
            window.location.replace('#services');
            this.setState({ tabIndex });
        }
    }

    render() {

        const { t } = this.props;

        return (
            <>
                <div>
                    <div className="whoWeAreDiv">
                        <div className="container">
                            <div className="whoWeAreDivText">{t('Who we are')}</div>
                            <div className="whoWeAreSubDiv desktop">
                                <div className="whoWeAreSubDigBussDiv">
                                    <div className="whoWeAreSubDigBussImgDiv"></div>
                                    <div className="whoWeAreSubDigBusstextDiv">{t('WhoWeAreTitle1')}</div>
                                </div>
                                <div className="whoWeAreSubPlusDiv"></div>
                                <div className="whoWeAreSubIntMarDiv">
                                    <div className="whoWeAreSubIntMartextDiv">
                                        {t('WhoWeAreTitle2')}
                                    </div>
                                    <div className="whoWeAreSubIntMartex1tDiv">
                                        {t('WhoWeAreDetails')}
                                    </div>
                                </div>
                            </div>
                            <div className="whoWeAreSubDiv mobile">
                                <div className="whoWeAreSubDigBussDiv">
                                    <div className="who-we-bg-text">
                                        <div className="whoWeAreSubDigBussImgDiv"></div>
                                        <h3>{t('WhoWeAreTitle1')}</h3>
                                        <div className="clearfix"></div>
                                        <div className="who-we-section-two">
                                            <div className="whoWeAreSubDigBussImgDiv"></div>
                                            <h3>{t('WhoWeAreTitle2')}</h3>
                                            <p>{t('WhoWeAreDetails')}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="clientDiv" id="services">
                        <div className="container">
                            <div className="servisesDivText">{t('Services')}</div>
                            <div>
                                <Tabs ref={this.tabs} selectedIndex={this.state.tabIndex} onSelect={tabIndex => this.setState({ tabIndex })}>
                                    <TabList>
                                        <Tab>{t('ServicesList1')}</Tab>
                                        <Tab>{t('ServicesList2')}</Tab>
                                        <Tab>{t('ServicesList3')}</Tab>
                                        <Tab>{t('ServicesList4')}</Tab>
                                    </TabList>
                                    <TabPanel>
                                        <div className="tab-pane fade in active show desktop">
                                            <div className="tabImageDiv">
                                                <div className="tabImageBGDiv1"></div>
                                                <div className="tabImagetextDiv">{t('ServicesList1')}</div>
                                            </div>
                                            <div className="tabTexteDiv">
                                                <div className="tabInnerTexteDiv">
                                                    <h4>{t('ServicesMainTitle1')}</h4>

                                                    {t('ServicesMainContent1')}
                                                </div>
                                                <Link to={`/${this.props.language.toLowerCase()}/${this.props.country.toLowerCase()}/search`}>
                                                    <button type="button" className="btn btn-primary tabButtons" id="searchButton">
                                                        {t('Book Now')}
                                                    </button>
                                                </Link>
                                            </div>
                                            <div className="clearfix"></div>
                                        </div>
                                        <div className="ServiceTab mobile">
                                            <div className="space-30"></div>

                                            <div className="who-we-bg-text">
                                                <div className="ServicesSubDigBussImgDiv1"></div>
                                                <div className="right">
                                                    <div className="service-tab-title">{t('ServicesList1')}</div>
                                                    <h3>{t('ServicesMainTitle1')}</h3>
                                                    <p>{t('ServicesMainContent1')}</p>
                                                    <Link to={`/${this.props.language.toLowerCase()}/${this.props.country.toLowerCase()}/search`}>
                                                        <button type="button" className="btn btn-primary" id="searchButton">Book Now</button>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row serversSmallDiv">
                                            <div className="col-6 col-md-6 col-sm-6">
                                                <div className="servers_smallDiv">
                                                    <h2 className="servers_smallIcon1">{t('ServicesBlock1Title')}</h2>
                                                    <div className="servers_smallTextDiv">
                                                        <ul>
                                                            <li>{t('Robotic Mower')}</li>
                                                            <li>{t('Garden Sheds')}</li>
                                                            <li>{t('Kitchen')}</li>
                                                            <li>{t('Home Security')}</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-6 col-md-6 col-sm-6">
                                                <div className="servers_smallDiv">
                                                    <h2 className="servers_smallIcon2">{t('ServicesBlock2Title')}</h2>
                                                    <div className="servers_smallTextDiv">
                                                        <ul>
                                                            <li>{t('Block2li1')}</li>
                                                            <li>{t('Block2li2')}</li>
                                                            <li>{t('Block2li3')}</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-6 col-md-6 col-sm-6">
                                                <div className="servers_smallDiv">
                                                    <h2 className="servers_smallIcon3">{t('ServicesBlock3Title')}</h2>
                                                    <div className="servers_smallTextDiv">
                                                        {t('ServicesBlock3Detail')}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </TabPanel>
                                    <TabPanel>
                                        <div className="tab-pane fade in active show desktop" id="Section2">
                                            <div className="tabImageDiv">
                                                <div className="tabImageBGDiv2"></div>
                                                <div className="tabImagetextDiv">{t('ServicesList2')}</div>
                                            </div>
                                            <div className="tabTexteDiv">
                                                <div className="tabInnerTexteDiv">
                                                    <h4>{t('ServicesMainTitle2')}</h4>

                                                    {t('ServicesMainContent2')}
                                                </div>
                                                <Link to={`/${this.props.language.toLowerCase()}/${this.props.country.toLowerCase()}/search`}>
                                                    <button type="button" className="btn btn-primary tabButtons" id="searchButton">
                                                        {t('Book Now')}
                                                    </button>
                                                </Link>
                                            </div>
                                            <div className="clearfix"></div>
                                        </div>
                                        <div className="ServiceTab mobile">
                                            <div className="space-30"></div>

                                            <div className="who-we-bg-text">
                                                <div className="ServicesSubDigBussImgDiv2"></div>

                                                <div className="right">
                                                    <div className="service-tab-title">{t('ServicesList2')}</div>
                                                    <h3>{t('ServicesMainTitle2')}</h3>
                                                    <p>{t('ServicesMainContent2')}</p>
                                                    <Link to={`/${this.props.language.toLowerCase()}/${this.props.country.toLowerCase()}/search`}>
                                                        <button type="button" className="btn btn-primary" id="searchButton">Book Now</button>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row serversSmallDiv">
                                            <div className="col-6 col-md-6 col-sm-6">
                                                <div className="servers_smallDiv">
                                                    <h2 className="servers_smallIcon1">{t('ServicesBlock1Title')}</h2>
                                                    <div className="servers_smallTextDiv">
                                                        <ul>
                                                            <li>{t('Robotic Mower')}</li>
                                                            <li>{t('Garden Sheds')}</li>
                                                            <li>{t('Kitchen')}</li>
                                                            <li>{t('Home Security')}</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-6 col-md-6 col-sm-6">
                                                <div className="servers_smallDiv">
                                                    <h2 className="servers_smallIcon2">{t('ServicesBlock2Title')}</h2>
                                                    <div className="servers_smallTextDiv">
                                                        <ul>
                                                            <li>{t('Block2li1')}</li>
                                                            <li>{t('Block2li2')}</li>
                                                            <li>{t('Block2li3')}</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-6 col-md-6 col-sm-6">
                                                <div className="servers_smallDiv">
                                                    <h2 className="servers_smallIcon3">{t('ServicesBlock3Title')}</h2>
                                                    <div className="servers_smallTextDiv">
                                                        {t('ServicesBlock3Detail')}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </TabPanel>
                                    <TabPanel>
                                        <div className="tab-pane fade in active show desktop" id="Section3">
                                            <div className="tabImageDiv">
                                                <div className="tabImageBGDiv3"></div>
                                                <div className="tabImagetextDiv">{t('ServicesList3')}</div>
                                            </div>
                                            <div className="tabTexteDiv">
                                                <div className="tabInnerTexteDiv">
                                                    <h4>{t('ServicesMainTitle3')}</h4>

                                                    {t('ServicesMainContent3')}
                                                </div>
                                                <Link to={`/${this.props.language.toLowerCase()}/${this.props.country.toLowerCase()}/search`}>
                                                    <button type="button" className="btn btn-primary tabButtons" id="searchButton">
                                                        {t('Book Now')}
                                                    </button>
                                                </Link>
                                            </div>
                                            <div className="clearfix"></div>
                                        </div>
                                        <div className="ServiceTab mobile">
                                            <div className="space-30"></div>

                                            <div className="who-we-bg-text">
                                                <div className="ServicesSubDigBussImgDiv3"></div>
                                                <div className="right">
                                                    <div className="service-tab-title">{t('ServicesList3')}</div>
                                                    <h3>{t('ServicesMainTitle3')}</h3>
                                                    <p>{t('ServicesMainContent3')}</p>
                                                    <Link to={`/${this.props.language.toLowerCase()}/${this.props.country.toLowerCase()}/search`}>
                                                        <button type="button" className="btn btn-primary" id="searchButton">Book Now</button>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row serversSmallDiv">
                                            <div className="col-6 col-md-6 col-sm-6">
                                                <div className="servers_smallDiv">
                                                    <h2 className="servers_smallIcon1">{t('ServicesBlock1Title')}</h2>
                                                    <div className="servers_smallTextDiv">
                                                        <ul>
                                                            <li>{t('Robotic Mower')}</li>
                                                            <li>{t('Garden Sheds')}</li>
                                                            <li>{t('Kitchen')}</li>
                                                            <li>{t('Home Security')}</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-6 col-md-6 col-sm-6">
                                                <div className="servers_smallDiv">
                                                    <h2 className="servers_smallIcon2">{t('ServicesBlock2Title')}</h2>
                                                    <div className="servers_smallTextDiv">
                                                        <ul>
                                                            <li>{t('Block2li1')}</li>
                                                            <li>{t('Block2li2')}</li>
                                                            <li>{t('Block2li3')}</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-6 col-md-6 col-sm-6">
                                                <div className="servers_smallDiv">
                                                    <h2 className="servers_smallIcon3">{t('ServicesBlock3Title')}</h2>
                                                    <div className="servers_smallTextDiv">
                                                        {t('ServicesBlock3Detail')}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </TabPanel>
                                    <TabPanel>
                                        <div className="tab-pane fade in active show desktop" id="Section4">
                                            <div className="tabImageDiv">
                                                <div className="tabImageBGDiv4"></div>
                                                <div className="tabImagetextDiv">{t('ServicesList4')}</div>
                                            </div>
                                            <div className="tabTexteDiv">
                                                <div className="tabInnerTexteDiv">
                                                    <h4>{t('ServicesMainTitle4')}</h4>

                                                    {t('ServicesMainContent4')}
                                                </div>
                                                <Link to={`/${this.props.language.toLowerCase()}/${this.props.country.toLowerCase()}/search`}>
                                                    <button type="button" className="btn btn-primary tabButtons" id="searchButton">
                                                        {t('Book Now')}
                                                    </button>
                                                </Link>
                                            </div>
                                            <div className="clearfix"></div>
                                        </div>
                                        <div className="ServiceTab mobile">
                                            <div className="space-30"></div>

                                            <div className="who-we-bg-text">
                                                <div className="ServicesSubDigBussImgDiv4"></div>
                                                <div className="right">
                                                    <div className="service-tab-title">{t('ServicesList4')}</div>
                                                    <h3>{t('ServicesMainTitle4')}</h3>
                                                    <p>{t('ServicesMainContent4')}</p>
                                                    <Link to={`/${this.props.language.toLowerCase()}/${this.props.country.toLowerCase()}/search`}>
                                                        <button type="button" className="btn btn-primary" id="searchButton">Book Now</button>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row serversSmallDiv">
                                            <div className="col-6 col-md-6 col-sm-6">
                                                <div className="servers_smallDiv">
                                                    <h2 className="servers_smallIcon1">{t('ServicesBlock1Title')}</h2>
                                                    <div className="servers_smallTextDiv">
                                                        <ul>
                                                            <li>{t('Robotic Mower')}</li>
                                                            <li>{t('Garden Sheds')}</li>
                                                            <li>{t('Kitchen')}</li>
                                                            <li>{t('Home Security')}</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-6 col-md-6 col-sm-6">
                                                <div className="servers_smallDiv">
                                                    <h2 className="servers_smallIcon2">{t('ServicesBlock2Title')}</h2>
                                                    <div className="servers_smallTextDiv">
                                                        <ul>
                                                            <li>{t('Block2li1')}</li>
                                                            <li>{t('Block2li2')}</li>
                                                            <li>{t('Block2li3')}</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-6 col-md-6 col-sm-6">
                                                <div className="servers_smallDiv">
                                                    <h2 className="servers_smallIcon3">{t('ServicesBlock3Title')}</h2>
                                                    <div className="servers_smallTextDiv">
                                                        {t('ServicesBlock3Detail')}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </TabPanel>
                                </Tabs>
                            </div>


                        </div>
                    </div>
                </div>
                <div>
                    <div className="container home-book-ur-ser">
                        <div className="bookServiceNowDiv">
                            <span>
                                {t('CTA Title')}
                            </span>
                            <Link to={`/${this.props.language.toLowerCase()}/${this.props.country.toLowerCase()}/search`}>
                                <button type="button" className="btn btn-primary bookServiceNowDivButton" id="bookServiceNowDivButton">
                                    {t('CTA Button')}
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default AboutPage;
