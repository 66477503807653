import React, { Component } from 'react';

import Page from '../page';
import About from '../about';
import SupportCTA from '../supportcta';

export default class extends Component {

    render() {

        return (
            <Page {...this.props}>
                <SupportCTA />
                <About />
            </Page>
        );
    }
};
